<template>
  <v-row justify="left">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('newgroup') }}
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('newgroup') }} </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="groupName"
                  append-icon="persons"
                  name="Group name"
                  type="text"
                  :label="$t('groupname')"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <small>{{ $t('required') }}</small>
        </v-card-text>
        <v-card-actions class="mt-0">
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addNewGroup(groupName)"
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import userService from '../services/user.service'
  export default {
    data: () => ({
      dialog: false,
      groupName: '',
    }),
    methods: {
      addNewGroup (name) {
        userService.createNewGroup(name)
        this.dialog = false
      },
    },
  }
</script>
