<template>
  <v-card
    class="groupRender ml"
    elevation="2"
    tile
    dense
    width="500"
  >
    <v-card-text class="mx-0 ml-1">
      <v-row
        no-gutters
        dense
        align="center"
        class="mx-1"
      >
        <v-icon large>
          mdi-account-group
        </v-icon>
        <v-card-title>
          {{ group.nickname }}
        </v-card-title>
        <v-spacer />
        <v-btn
          color="#f96868"
          class="mx-1"
          @click="deleteGroup(group.id)"
        >
          {{ $t('delete') }}
          <v-icon right>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-row>
      <v-row
        no-gutters
        dense
        align="center"
        class="mx-1"
      >
        <v-icon large>
          mdi-link-box-variant
        </v-icon>
        <v-card-title> {{ group.id }}-{{ group.joinCode }} </v-card-title>
        <v-spacer />
        <v-btn
          color="#48b0f7"
          class="mx-1"
        >
          {{ $t('grafer') }}

          <v-icon right>
            mdi-chart-box
          </v-icon>
        </v-btn>
      </v-row>

      <v-row
        no-gutters
        dense
        align="center"
        class="mx-0"
      >
        <v-icon large>
          mdi-account
        </v-icon>

        <v-card-title>
          {{ memberList.length }}
        </v-card-title>
        <v-spacer />
        <v-card-actions>
          <v-btn
            icon
            text
            @click="show = !show"
          >
            <v-icon>
              {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>{{ $t('members') }}
        </v-card-actions>
      </v-row>

      <v-card
        v-for="member in memberList"
        v-show="show"
        :key="member._nickname"
        tile
        dense
        color="#d9d9d9"
        class="mx-auto mt-1 mb-2"
      >
        <v-row
          no-gutters
          dense
          align="center"
          class="mx-1"
        >
          <v-icon>mdi-account</v-icon>
          {{ member._nickname }}
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
  import UserService from '@/services/user.service'

  export default {
    components: {},
    props: {
      group: { type: Object, required: true },
      memberList: { type: Array, default: () => [] },
      show: { type: Boolean, default: false },
      groupId: { type: String, required: true },
    },
    mounted () {
      UserService.getGroupMembers(this.groupId).then(response => {
        this.memberList = response.data.members
      })
    },
    methods: {
      deleteGroup (id) {
        UserService.deleteGroup(id)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
