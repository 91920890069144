<template>
  <v-container
    id="group"
    fluid
  >
    <v-row class="headline mb-3 ml-1">
      <h3>
        {{ $t('mygroups') }}
      </h3>
      <new-group-form class="mb-4 ml-1" />
    </v-row>

    <div
      v-for="group in groups"
      :key="group.id"
    >
      <group-render
        :group="group"
        :group-id="group.id"
      />
    </div>

    <new-group-form class="mt-4 ml-1" />
  </v-container>
</template>

<script>
  import UserService from '@/services/user.service'
  import NewGroupForm from '../components/NewGroupForm.vue'
  import GroupRender from '../components/GroupRender.vue'

  export default {
    components: { NewGroupForm, GroupRender },

    data () {
      return {
        groups: '',
      }
    },
    mounted () {
      UserService.getUserGroups().then(response => {
        this.groups = response.data
      })
    },
  }
</script>
